/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module {
  height: calc(100vh - 60px);
}
.Module .module-header {
  background-color: #272e34;
  min-height: 62px;
  clear: both;
}
.Module .module-header .wrap {
  display: inline-flex;
  max-width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
}
.Module .module-header .module-header-info {
  height: 62px;
}
.Module .module-header .module-header-info .module-header-banner {
  font-size: x-large;
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title {
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title i,
.Module .module-header .module-header-info .module-header-title a {
  color: #0df;
}
.Module .module-header .module-header-info .module-header-title a {
  font-size: 1rem;
}
.Module .module-header .module-header-info .module-header-title i {
  transform: scale(0.9);
}
.Module .module-header .header-selected {
  border-radius: 0;
}
.Module .module-header .view-exit {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}
.Module .module-header .view-title {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
.Module .module-header .view-title .material-icons {
  color: #fff;
  font-size: 24px;
  padding-left: 20px;
}
.Module .module-header .header-bar {
  float: left;
  margin-left: 10px;
}
.Module .module-header .header-bar a .btn {
  margin-right: 10px;
}
.Module .module-header .header-bar .form-inline {
  float: left;
  padding-top: 11px;
  font-size: 1rem;
}
.Module .module-header .header-bar .form-inline .input-group {
  margin-right: 20px;
}
.Module .module-header .header-bar .form-inline input {
  display: inline-block;
}
.Module .module-header .header-bar .form-inline label {
  padding-right: 5px;
  text-transform: capitalize;
}
.Module .module-header .header-bar .form-inline ::placeholder {
  color: #dae0e7;
}
.Module .module-header .secondary-header-toolbar {
  float: right;
  margin-right: 10px;
}
.Module .module-header .header-menu {
  background-color: #272e34;
}
.Module .module-header .header-selector {
  padding: 10px;
}
.Module .module-header .header-selector label {
  padding-top: 10px;
  padding-right: 10px;
  margin: 0;
}
.Module .module-header .header-selector .material-icons {
  padding-top: 5px;
}
.Module .module-header .header-selector .dropdown-menu-dark button {
  color: #aaaeb3;
  background-color: #272e34;
}
.Module .module-header .header-disabled {
  visibility: hidden;
}
.Module .module-header .btn-toolbar .header-button-group .material-icons {
  position: relative;
  top: 2px;
  color: #aaaeb3;
  padding-right: 5px;
  font-size: 16px;
}
.Module .module-header .btn-toolbar .header-button-group .selected .material-icons {
  color: #4bb3d8;
}
.Module .module-header .btn-toolbar .btn-group {
  margin-right: 10px;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary {
  outline: none;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary:focus {
  outline: none;
  box-shadow: none;
}
.Module .module-header .btn-toolbar .btn-group .btn {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-header .btn-toolbar .date-range-group .selected .material-icons {
  color: #fff;
}
.Module .module-content {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #272e34;
  height: calc(100% - 62px);
}
.Module .module-content .header-id {
  font-size: small;
  color: #aaaeb3;
  padding-left: 10px;
}
.Module .module-content .content-view {
  height: 100%;
}
.Module .module-content .force {
  position: fixed;
  bottom: 0;
  margin-right: 20px;
  width: calc(100vw - 190px - 10px);
}
.Module .module-content .content-view-controls {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  padding-left: 0;
  height: 50px;
  background-color: #272e34;
}
.Module .module-content .content-view-controls a .btn:last-child {
  margin-right: 10px;
}
.Module .module-content .content-view-controls .header-button-group .btn {
  margin: 0;
}
.Module .module-content .content-view-controls label {
  margin-top: 5px;
  margin-right: 5px;
}
.Module .module-content .content-view-controls form {
  margin-right: 10px;
}
.Module .module-content .content-view-controls form .form-check-label-color {
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-top: 0;
}
.Module .module-content .content-view-controls .form-check-inline {
  margin-right: 0;
}
.Module .module-content .search-bar {
  position: fixed;
  width: calc(100% - 190px);
  padding-right: 20px;
}
.Module .module-content .search-bar .input-group {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group .input-group-text {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .search-bar .input-group button,
.Module .module-content .search-bar .input-group input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
}
.Module .module-content .search-bar .input-group-sm {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group-sm .input-group-text {
  background-color: #272e34;
  color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .input-group-sm button,
.Module .module-content .search-bar .input-group-sm input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .form-check-label {
  padding: 0 5px;
  margin-right: 10px;
  border-radius: 5px;
}
.Module .module-content .content {
  float: left;
  width: calc(100% - 330px - 10px - 2px);
  height: 100%;
  background-color: #353c44;
}
.Module .module-content .content .content-with-title {
  height: 100%;
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main {
  height: calc(100% - 300px);
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main-full {
  height: 100%;
}
.Module .module-content .content .content-footer {
  height: 300px;
  width: 100%;
}
.Module .module-content .content .ok {
  background-color: #4caf50;
}
.Module .module-content .content .warning {
  background-color: orange;
}
.Module .module-content .content .error {
  background-color: #e22006;
}
.Module .module-content .content .no-status {
  background-color: #272e34;
}
.Module .module-content .content .no-match {
  text-align: center;
}
.Module .module-content .content .content-title {
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
}
.Module .module-content .content .content-subtitle {
  text-align: center;
  padding: 10px;
  margin: 0;
}
.Module .module-content .content .site-content {
  padding-bottom: 50px;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.ok {
  color: #4caf50;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.warning {
  color: orange;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.error {
  color: #e22006;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.no-status {
  color: #fff;
}
.Module .module-content .content .site-content td.ranged {
  background-color: #272e34;
}
.Module .module-content .content .system-content {
  clear: both;
}
.Module .module-content .content table {
  width: 100%;
  border-collapse: separate;
  /* Don't collapse as this makes sticky headers see through */
  border-spacing: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content table th {
  top: 0;
  border-bottom: 2px solid #272e34;
  background-color: #353c44;
  z-index: 1;
  color: #4bb3d8;
  text-align: left;
}
.Module .module-content .content table th.ranged {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content table td {
  border: solid 1px #272e34;
}
.Module .module-content .content table td a {
  color: #fff;
}
.Module .module-content .content table td .btn-link {
  padding: 0;
  padding-left: 5px;
}
.Module .module-content .content table td .btn-link .material-icons {
  color: #4bb3d8;
}
.Module .module-content .content table td.danger {
  background-color: #e22006;
}
.Module .module-content .content table td.ranged {
  background-color: #353c44;
}
.Module .module-content .content table th.edit {
  width: 20px;
}
.Module .module-content .content .content-map {
  height: 100%;
  width: 100%;
}
.Module .module-content .content nav {
  background-color: #272e34;
  z-index: 100;
  color: #fff;
}
.Module .module-content .content nav .react-contextmenu-item {
  color: #fff;
}
.Module .module-content .content nav .ok > .react-contextmenu-item {
  background-color: #4caf50;
}
.Module .module-content .content nav .warning > .react-contextmenu-item {
  background-color: orange;
}
.Module .module-content .content nav .error > .react-contextmenu-item {
  background-color: #e22006;
}
.Module .module-content .content nav .no-nodes > .react-contextmenu-item {
  padding: 0 10px;
}
.Module .module-content .content .content-graph-menu {
  text-align: center;
  background-color: #272e34;
  clear: both;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-left {
  font-weight: bold;
  float: left;
  margin-left: 10px;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-right {
  float: right;
  margin-right: 10px;
}
.Module .module-content .content .content-graph-menu .dropdown {
  display: inline;
  z-index: 10;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-menu {
  background-color: #1f1f1f;
}
.Module .module-content .content .content-graph-menu .dropdown button {
  color: #fff;
  background-color: #272e34;
  border: none;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-item:hover {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-toggle {
  color: #fff;
  box-shadow: none;
}
.Module .module-content .content .content-graph-menu .dropdown .selected {
  color: #4bb3d8;
}
.Module .module-content .content .content-graph-empty {
  text-align: center;
  font-size: large;
  padding-top: 100px;
}
.Module .module-content .content .content-graph,
.Module .module-content .content .content-source-graph {
  width: 100%;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper {
  pointer-events: auto;
  margin-left: -5px;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip {
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list {
  max-height: 165px;
}
.Module .module-content .content .content-graph {
  height: calc(300px - 30px);
}
.Module .module-content .content .content-source-graph {
  height: calc(300px + 230px);
}
.Module .module-content .content-full {
  width: 100%;
  overflow-y: auto;
}
.Module .module-content .content-full-with-title {
  width: 100%;
  height: calc(100% - 66px);
  overflow-y: auto;
}
.Module .module-content .close-button {
  top: 0;
  font-size: 48px;
}
.Module .module-content .item-details {
  height: 100%;
}
.Module .module-content .item-details .item-details-title {
  padding: 10px 20px;
  font-size: large;
}
.Module .module-content .item-details .item-details-title h2 {
  padding: 0;
}
.Module .module-content .item-details .item-details-title h2 .right-title {
  float: right;
  font-size: medium;
  padding-top: 14px;
}
.Module .module-content .item-details .item-create-title {
  background-color: #4bb3d8;
}
.Module .module-content .item-details .item-details-content {
  overflow: auto;
  height: calc(100% - 66px);
  background-color: #272e34;
  padding: 20px;
}
.Module .module-content .item-details .item-details-content label {
  color: #4bb3d8;
  margin-right: 5px;
}
.Module .module-content .item-details .item-details-content .ecogy-form label {
  margin-right: 0;
}
.Module .module-content .item-details .item-details-content .type {
  text-transform: capitalize;
}
.Module .module-content .item-details .item-details-content hr {
  margin: 2px;
  border-top-color: #dae0e7;
}
.Module .module-content .item-details .item-details-content .remove-button {
  margin-bottom: 20px;
}
.Module .module-content .item-details .item-details-content form > .form-group fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content form > .form-group > div > div > fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content .right-column {
  float: right;
  clear: both;
  padding-right: 0;
  padding-left: 10px;
}
.Module .module-content .content-sidebar {
  margin-left: 10px;
  float: left;
  width: 330px;
  height: 100%;
  overflow: auto;
}
.Module .module-content .content-sidebar .sidebar-section {
  margin-top: 10px;
  background-color: #353c44;
  width: 100%;
  padding: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-subtitle {
  color: #aaaeb3;
  float: right;
  margin: 0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-range {
  color: #aaaeb3;
  margin: 0;
  font-size: small;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content {
  width: 100%;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-heading {
  font-size: xx-large;
  font-weight: bold;
  color: #4bb3d8;
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .positive {
  color: #00c4a0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .negative {
  color: #ff9b91;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text {
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text .material-icons {
  float: right;
  padding-top: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-reading {
  color: #4bb3d8;
  font-size: x-large;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .warning {
  color: orange;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-error {
  color: #4bb3d8;
  font-size: large;
  text-align: center;
  padding: 10px 0;
}
.Module .module-content .content-sidebar :first-child {
  margin: 0;
}
.Module .module-content .content-sidebar .date-range {
  color: #aaaeb3;
}
.Module .module-content .content-sidebar .date-range .start-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .end-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .date-label {
  width: 50px;
}
.Module .module-content .recharts-tooltip-cursor {
  fill-opacity: 25%;
}
.Module .module-content .content-with-controls {
  height: calc(100% - 50px);
}
.Module .collapsed {
  height: calc(100% - 62px - 41px);
}

.App-portfolio .module-header {
  background-color: #4bb3d8;
  min-height: 61px;
  border-bottom: 1px solid black;
}
.App-portfolio .module-content {
  margin-top: 10px;
  height: calc(100% - 62px - 10px);
}
.App-portfolio .collapsed {
  height: calc(100% - 62px - 41px - 10px);
}

.area-graph-tooltip {
  background: #fff;
  padding: 10px;
  color: #272e34;
  text-transform: capitalize;
}
.area-graph-tooltip p {
  margin: 0;
}

.econode-tooltip .area-graph-tooltip,
.ecogy-tooltip .area-graph-tooltip {
  text-transform: none;
}

.modal {
  color: #000;
}
.modal .error {
  color: #e22006;
}

.custom-range-button {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-range-name > * {
  float: left;
}
.custom-range-name .range .range-start {
  font-size: small;
}
.custom-range-name .range .range-end {
  font-size: small;
}
.custom-range-name .range .range-divider {
  display: none;
}

.date-range-menu {
  width: 700px;
  height: 370px;
  left: auto;
  right: 0;
}
.date-range-menu .custom-date-range {
  float: left;
  width: 510px;
}
.date-range-menu .custom-date-range .custom-date {
  float: left;
  width: 250px;
  margin-left: 5px;
  height: 310px;
}
.date-range-menu .precanned-date-ranges {
  float: left;
  margin-left: 10px;
  height: 100%;
  width: 170px;
  background-color: #353c44;
  border-radius: 5px;
  padding: 10px;
}
.date-range-menu .precanned-date-ranges .dropdown-item {
  padding: 0.25rem;
  border: none;
}
.date-range-menu .precanned-date-ranges .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.date-range-menu .date-range-buttons {
  clear: both;
}
.date-range-menu .date-range-buttons .date-range-button {
  border-radius: 10px;
  float: right;
}
.date-range-menu .date-range-message {
  width: 100%;
  color: #4bb3d8;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-message .material-icons {
  color: #4bb3d8;
}
.date-range-menu .date-range-warning {
  width: 100%;
  color: #e22006;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-warning .material-icons {
  color: #e22006;
}

.battery-view-header {
  text-align: center;
}

.battery-box {
  height: 150px;
  width: 200px;
  background-color: rgb(29, 33, 39);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 75px 75px;
  color: grey;
}

.battery-container {
  text-align: center;
  align-items: center;
  justify-items: center;
  height: 350px;
  width: 250px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 80px 230px;
  grid-template-rows: 80px 150px 80px;
  color: rgb(207, 205, 205);
}
.battery-container p {
  font-size: medium;
}
.battery-container .state-of-health {
  margin-top: 8px;
  margin-bottom: 40px;
  background-color: #193138;
  border-radius: 5px;
  font-size: small;
}

.graph-tail-end {
  height: 8px;
  width: 8px;
  background-color: #00C49F;
  bottom: 180px;
  left: 0px;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
}

.battery-cell {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #1c3841;
  height: 100px;
  width: 70px;
  position: relative;
}
.battery-cell .logo {
  position: relative;
  top: 40px;
  left: -14px;
  max-width: 100px;
  filter: opacity(0.6) drop-shadow(0 0 0 rgb(0, 0, 0));
  transform: rotate(270deg);
}
.battery-cell .charge-bar {
  margin: 5px;
  position: absolute;
  width: 60px;
  bottom: 0px;
  background-color: #4caf50;
}
.battery-cell .empty {
  visibility: hidden;
}
.battery-cell .low {
  background-color: #e22006;
}
.battery-cell .high {
  background-color: #4caf50;
}
.battery-cell .charge-bar-wrapper {
  position: absolute;
  height: 90px;
  width: 60px;
  bottom: 0px;
}
.battery-cell .charge-bar-top {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  top: -10px;
  box-shadow: inset 0px -6px 12px 0px hsla(0, 0%, 0%, 0.2);
}
.battery-cell .charge-bar-bottom {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  bottom: -10px;
}
.battery-cell .battery-cell-top {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -10px;
  background-color: #193138;
  z-index: 100;
}
.battery-cell .battery-cell-top2 {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -5px;
  background-color: #1c3841;
  z-index: 99;
}
.battery-cell .battery-cell-bottom {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  bottom: -10px;
  background-color: #1c3841;
}

[class|=confetti] {
  position: absolute;
  z-index: 3000;
}

.papers-origin {
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
}

.confetti-0 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(148.6757415416deg);
  animation: drop-0 3.6119256328s 0.1483504553s 3;
}

@keyframes drop-0 {
  25% {
    top: -21vh;
    left: -12vw;
    opacity: 0.5540362408;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-1 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(349.3777742742deg);
  animation: drop-1 3.121482389s 0.3978790653s 3;
}

@keyframes drop-1 {
  25% {
    top: -12vh;
    left: -14vw;
    opacity: 1.0785217172;
  }
  100% {
    top: 50vh;
    left: -22vw;
    opacity: 0;
  }
}
.confetti-2 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(274.0470138927deg);
  animation: drop-2 3.8191479721s 0.1197611159s 3;
}

@keyframes drop-2 {
  25% {
    top: -12vh;
    left: -1vw;
    opacity: 1.1132583163;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-3 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(284.6498276587deg);
  animation: drop-3 3.2960863719s 0.3424649043s 3;
}

@keyframes drop-3 {
  25% {
    top: -12vh;
    left: -12vw;
    opacity: 0.7357423725;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-4 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(4.903752806deg);
  animation: drop-4 3.7187664986s 0.9694742278s 3;
}

@keyframes drop-4 {
  25% {
    top: -21vh;
    left: 5vw;
    opacity: 0.5459044533;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-5 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(92.3570500007deg);
  animation: drop-5 3.5159443044s 0.0749521062s 3;
}

@keyframes drop-5 {
  25% {
    top: -15vh;
    left: 4vw;
    opacity: 1.3958791399;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}
.confetti-6 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(224.8221311574deg);
  animation: drop-6 3.5526874511s 0.2301924416s 3;
}

@keyframes drop-6 {
  25% {
    top: -25vh;
    left: -8vw;
    opacity: 1.4108956053;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-7 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(325.8101670828deg);
  animation: drop-7 3.3552452418s 0.2893314321s 3;
}

@keyframes drop-7 {
  25% {
    top: -25vh;
    left: 7vw;
    opacity: 1.0567912798;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-8 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(262.7564624746deg);
  animation: drop-8 3.6065500885s 0.5034309186s 3;
}

@keyframes drop-8 {
  25% {
    top: -4vh;
    left: 11vw;
    opacity: 1.2204631903;
  }
  100% {
    top: 50vh;
    left: 13vw;
    opacity: 0;
  }
}
.confetti-9 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(219.9585739165deg);
  animation: drop-9 3.5385505182s 0.2047108569s 3;
}

@keyframes drop-9 {
  25% {
    top: -15vh;
    left: 11vw;
    opacity: 1.3745450475;
  }
  100% {
    top: 50vh;
    left: 26vw;
    opacity: 0;
  }
}
.confetti-10 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(260.7522861418deg);
  animation: drop-10 3.9322531137s 0.5737074339s 3;
}

@keyframes drop-10 {
  25% {
    top: -23vh;
    left: 11vw;
    opacity: 1.4924430552;
  }
  100% {
    top: 50vh;
    left: 22vw;
    opacity: 0;
  }
}
.confetti-11 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(226.8511827991deg);
  animation: drop-11 3.8105716116s 0.9206904794s 3;
}

@keyframes drop-11 {
  25% {
    top: -18vh;
    left: -13vw;
    opacity: 1.3129400548;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-12 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(357.0759436299deg);
  animation: drop-12 3.5560120033s 0.3120291514s 3;
}

@keyframes drop-12 {
  25% {
    top: -18vh;
    left: 2vw;
    opacity: 0.7030694524;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-13 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(98.9007210569deg);
  animation: drop-13 3.8893566725s 0.4223431097s 3;
}

@keyframes drop-13 {
  25% {
    top: -13vh;
    left: -2vw;
    opacity: 1.116513316;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-14 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(282.9594615927deg);
  animation: drop-14 3.822541192s 0.5107994794s 3;
}

@keyframes drop-14 {
  25% {
    top: -18vh;
    left: 1vw;
    opacity: 0.9382220814;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-15 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(251.1797394485deg);
  animation: drop-15 3.8829331379s 0.0458487697s 3;
}

@keyframes drop-15 {
  25% {
    top: -10vh;
    left: -4vw;
    opacity: 0.85895603;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-16 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(123.8569800011deg);
  animation: drop-16 3.7899758685s 0.0313483003s 3;
}

@keyframes drop-16 {
  25% {
    top: -15vh;
    left: 12vw;
    opacity: 0.5956485744;
  }
  100% {
    top: 50vh;
    left: 23vw;
    opacity: 0;
  }
}
.confetti-17 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(209.4388239293deg);
  animation: drop-17 3.9368857249s 0.6345887004s 3;
}

@keyframes drop-17 {
  25% {
    top: -4vh;
    left: -11vw;
    opacity: 0.665316948;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-18 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(99.5220392855deg);
  animation: drop-18 3.7387667772s 0.8699300463s 3;
}

@keyframes drop-18 {
  25% {
    top: -22vh;
    left: -9vw;
    opacity: 1.4905394394;
  }
  100% {
    top: 50vh;
    left: -20vw;
    opacity: 0;
  }
}
.confetti-19 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(259.3205338855deg);
  animation: drop-19 3.5631292658s 0.4715373893s 3;
}

@keyframes drop-19 {
  25% {
    top: -11vh;
    left: 14vw;
    opacity: 0.8269594676;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-20 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(121.5421614842deg);
  animation: drop-20 3.3426792454s 0.1702714163s 3;
}

@keyframes drop-20 {
  25% {
    top: -14vh;
    left: 12vw;
    opacity: 0.9498938461;
  }
  100% {
    top: 50vh;
    left: 17vw;
    opacity: 0;
  }
}
.confetti-21 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(24.4496371278deg);
  animation: drop-21 3.1812467312s 0.1908372715s 3;
}

@keyframes drop-21 {
  25% {
    top: -13vh;
    left: 11vw;
    opacity: 1.0122422181;
  }
  100% {
    top: 50vh;
    left: 19vw;
    opacity: 0;
  }
}
.confetti-22 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(156.6195711876deg);
  animation: drop-22 3.0395962349s 0.2078383343s 3;
}

@keyframes drop-22 {
  25% {
    top: -6vh;
    left: -4vw;
    opacity: 1.4188019638;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}
.confetti-23 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(310.2767692484deg);
  animation: drop-23 3.6659821298s 0.4270172247s 3;
}

@keyframes drop-23 {
  25% {
    top: -18vh;
    left: -2vw;
    opacity: 0.8115978932;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-24 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(51.4298368931deg);
  animation: drop-24 3.1863319612s 0.6399572682s 3;
}

@keyframes drop-24 {
  25% {
    top: -1vh;
    left: -12vw;
    opacity: 0.6334161984;
  }
  100% {
    top: 50vh;
    left: -5vw;
    opacity: 0;
  }
}
.confetti-25 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(156.5015595406deg);
  animation: drop-25 3.158052226s 0.4335261778s 3;
}

@keyframes drop-25 {
  25% {
    top: -9vh;
    left: 5vw;
    opacity: 1.356527827;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-26 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(61.2968022098deg);
  animation: drop-26 3.5306042668s 0.4475127304s 3;
}

@keyframes drop-26 {
  25% {
    top: -20vh;
    left: -6vw;
    opacity: 1.3068774088;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-27 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(46.1929305936deg);
  animation: drop-27 3.0458677538s 0.2548848885s 3;
}

@keyframes drop-27 {
  25% {
    top: -16vh;
    left: 0vw;
    opacity: 1.2270919522;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-28 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(129.3335925532deg);
  animation: drop-28 3.0261831365s 0.7195300932s 3;
}

@keyframes drop-28 {
  25% {
    top: -20vh;
    left: 0vw;
    opacity: 1.2974938715;
  }
  100% {
    top: 50vh;
    left: -12vw;
    opacity: 0;
  }
}
.confetti-29 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(43.6164326226deg);
  animation: drop-29 3.609508156s 0.0634642333s 3;
}

@keyframes drop-29 {
  25% {
    top: -25vh;
    left: -1vw;
    opacity: 1.4666315907;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-30 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(70.5920055065deg);
  animation: drop-30 3.722737357s 0.6694191845s 3;
}

@keyframes drop-30 {
  25% {
    top: -22vh;
    left: -9vw;
    opacity: 1.1111186055;
  }
  100% {
    top: 50vh;
    left: -19vw;
    opacity: 0;
  }
}
.confetti-31 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(127.1932169366deg);
  animation: drop-31 3.5572469308s 0.2459951538s 3;
}

@keyframes drop-31 {
  25% {
    top: -8vh;
    left: 0vw;
    opacity: 1.4235530872;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-32 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(348.2525070746deg);
  animation: drop-32 3.5447871156s 0.7200681886s 3;
}

@keyframes drop-32 {
  25% {
    top: -5vh;
    left: -4vw;
    opacity: 1.1499816027;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-33 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(104.153125355deg);
  animation: drop-33 3.7235271837s 0.0360900136s 3;
}

@keyframes drop-33 {
  25% {
    top: -15vh;
    left: -14vw;
    opacity: 1.3005173619;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-34 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(215.3069269384deg);
  animation: drop-34 3.0658615536s 0.1418746326s 3;
}

@keyframes drop-34 {
  25% {
    top: -13vh;
    left: -2vw;
    opacity: 0.5058588879;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-35 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(296.240194971deg);
  animation: drop-35 3.3777512937s 0.2959710331s 3;
}

@keyframes drop-35 {
  25% {
    top: -12vh;
    left: -4vw;
    opacity: 0.7605821344;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-36 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(131.3989692123deg);
  animation: drop-36 3.5509038892s 0.6515731172s 3;
}

@keyframes drop-36 {
  25% {
    top: -4vh;
    left: -11vw;
    opacity: 0.6237738237;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-37 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(37.5187805231deg);
  animation: drop-37 3.912960886s 0.0117914603s 3;
}

@keyframes drop-37 {
  25% {
    top: -6vh;
    left: 14vw;
    opacity: 1.3526529172;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-38 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(162.3300302139deg);
  animation: drop-38 3.3654164674s 0.7384774182s 3;
}

@keyframes drop-38 {
  25% {
    top: -19vh;
    left: 5vw;
    opacity: 0.7101090363;
  }
  100% {
    top: 50vh;
    left: 19vw;
    opacity: 0;
  }
}
.confetti-39 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(186.0054116727deg);
  animation: drop-39 3.1659896873s 0.4572332926s 3;
}

@keyframes drop-39 {
  25% {
    top: -9vh;
    left: 3vw;
    opacity: 0.6878359308;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-40 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(41.5884835458deg);
  animation: drop-40 3.7939892685s 0.1837244005s 3;
}

@keyframes drop-40 {
  25% {
    top: -24vh;
    left: 13vw;
    opacity: 0.6604867151;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-41 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(278.7137819613deg);
  animation: drop-41 3.4690816779s 0.4645080309s 3;
}

@keyframes drop-41 {
  25% {
    top: -15vh;
    left: -9vw;
    opacity: 0.5423878002;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-42 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(178.2977008809deg);
  animation: drop-42 3.9102784738s 0.6766372002s 3;
}

@keyframes drop-42 {
  25% {
    top: -10vh;
    left: 1vw;
    opacity: 0.9669876779;
  }
  100% {
    top: 50vh;
    left: 16vw;
    opacity: 0;
  }
}
.confetti-43 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(207.8676044403deg);
  animation: drop-43 3.0662281372s 0.6647397471s 3;
}

@keyframes drop-43 {
  25% {
    top: -24vh;
    left: -14vw;
    opacity: 0.8407502951;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-44 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(249.479051852deg);
  animation: drop-44 3.0328595213s 0.8637646903s 3;
}

@keyframes drop-44 {
  25% {
    top: -17vh;
    left: 13vw;
    opacity: 1.081413966;
  }
  100% {
    top: 50vh;
    left: 23vw;
    opacity: 0;
  }
}
.confetti-45 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(265.7348465377deg);
  animation: drop-45 3.7128114642s 0.8727298779s 3;
}

@keyframes drop-45 {
  25% {
    top: -9vh;
    left: 6vw;
    opacity: 1.249330562;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-46 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(204.6735446219deg);
  animation: drop-46 3.9570274556s 0.4225722595s 3;
}

@keyframes drop-46 {
  25% {
    top: -16vh;
    left: -14vw;
    opacity: 1.4558965356;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-47 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(64.661052346deg);
  animation: drop-47 3.2902627276s 0.2126391315s 3;
}

@keyframes drop-47 {
  25% {
    top: -9vh;
    left: -2vw;
    opacity: 0.909256143;
  }
  100% {
    top: 50vh;
    left: -4vw;
    opacity: 0;
  }
}
.confetti-48 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(228.7516881465deg);
  animation: drop-48 3.3864966144s 0.1946583919s 3;
}

@keyframes drop-48 {
  25% {
    top: -16vh;
    left: -8vw;
    opacity: 0.6544654058;
  }
  100% {
    top: 50vh;
    left: -18vw;
    opacity: 0;
  }
}
.confetti-49 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(232.6685870269deg);
  animation: drop-49 3.7707430861s 0.0452956462s 3;
}

@keyframes drop-49 {
  25% {
    top: -17vh;
    left: 11vw;
    opacity: 0.9626728324;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-50 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(113.8810687993deg);
  animation: drop-50 3.505843794s 0.4005514044s 3;
}

@keyframes drop-50 {
  25% {
    top: -25vh;
    left: -9vw;
    opacity: 1.0121311364;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-51 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(331.750538752deg);
  animation: drop-51 3.710487585s 0.5830238812s 3;
}

@keyframes drop-51 {
  25% {
    top: -15vh;
    left: -1vw;
    opacity: 0.57909011;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-52 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(6.3938410977deg);
  animation: drop-52 3.4182445557s 0.1328266028s 3;
}

@keyframes drop-52 {
  25% {
    top: -14vh;
    left: 6vw;
    opacity: 0.6851409776;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-53 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(307.7963025884deg);
  animation: drop-53 3.284242127s 0.1891865294s 3;
}

@keyframes drop-53 {
  25% {
    top: -25vh;
    left: -10vw;
    opacity: 0.6233956523;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-54 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(244.1595834849deg);
  animation: drop-54 3.4675942739s 0.7229125962s 3;
}

@keyframes drop-54 {
  25% {
    top: -16vh;
    left: -6vw;
    opacity: 0.7342595971;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-55 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(333.3403683108deg);
  animation: drop-55 3.2616188739s 0.7741427915s 3;
}

@keyframes drop-55 {
  25% {
    top: -6vh;
    left: 9vw;
    opacity: 1.3803671689;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-56 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(322.7831964923deg);
  animation: drop-56 3.4249706212s 0.1223251326s 3;
}

@keyframes drop-56 {
  25% {
    top: -3vh;
    left: 1vw;
    opacity: 1.2192055139;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-57 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(75.4121904716deg);
  animation: drop-57 3.1831054287s 0.2676526755s 3;
}

@keyframes drop-57 {
  25% {
    top: -4vh;
    left: -10vw;
    opacity: 0.5556607292;
  }
  100% {
    top: 50vh;
    left: -21vw;
    opacity: 0;
  }
}
.confetti-58 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(79.5070267932deg);
  animation: drop-58 3.1410256437s 0.5616274566s 3;
}

@keyframes drop-58 {
  25% {
    top: -7vh;
    left: 2vw;
    opacity: 0.7971720121;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-59 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(224.0291871339deg);
  animation: drop-59 3.7988829639s 0.5856643646s 3;
}

@keyframes drop-59 {
  25% {
    top: -18vh;
    left: 1vw;
    opacity: 1.3134078848;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}
.confetti-60 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(194.1154749857deg);
  animation: drop-60 3.9552427036s 0.3878483911s 3;
}

@keyframes drop-60 {
  25% {
    top: -14vh;
    left: -10vw;
    opacity: 1.2629664447;
  }
  100% {
    top: 50vh;
    left: -16vw;
    opacity: 0;
  }
}
.confetti-61 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(281.4669814356deg);
  animation: drop-61 3.3586804785s 0.5265032152s 3;
}

@keyframes drop-61 {
  25% {
    top: -13vh;
    left: 9vw;
    opacity: 1.1038464503;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-62 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(247.3947812303deg);
  animation: drop-62 3.0722552803s 0.8912159622s 3;
}

@keyframes drop-62 {
  25% {
    top: -14vh;
    left: -10vw;
    opacity: 1.417318259;
  }
  100% {
    top: 50vh;
    left: -9vw;
    opacity: 0;
  }
}
.confetti-63 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(105.268682073deg);
  animation: drop-63 3.0271145041s 0.1071055342s 3;
}

@keyframes drop-63 {
  25% {
    top: -20vh;
    left: -13vw;
    opacity: 0.9282148777;
  }
  100% {
    top: 50vh;
    left: -16vw;
    opacity: 0;
  }
}
.confetti-64 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(14.5999081762deg);
  animation: drop-64 3.4130514265s 0.792661388s 3;
}

@keyframes drop-64 {
  25% {
    top: -1vh;
    left: -6vw;
    opacity: 1.0175964556;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-65 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(0.7093848113deg);
  animation: drop-65 3.2494914208s 0.9891984176s 3;
}

@keyframes drop-65 {
  25% {
    top: -12vh;
    left: -14vw;
    opacity: 1.1242971282;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-66 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(287.6461954004deg);
  animation: drop-66 3.9901948685s 0.2245519201s 3;
}

@keyframes drop-66 {
  25% {
    top: -9vh;
    left: 11vw;
    opacity: 0.7371256053;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}
.confetti-67 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(166.3037186762deg);
  animation: drop-67 3.8826905017s 0.3622958413s 3;
}

@keyframes drop-67 {
  25% {
    top: -24vh;
    left: 10vw;
    opacity: 1.0187182097;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-68 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(196.7184043174deg);
  animation: drop-68 3.0856686228s 0.9474773912s 3;
}

@keyframes drop-68 {
  25% {
    top: -14vh;
    left: -7vw;
    opacity: 1.4081529538;
  }
  100% {
    top: 50vh;
    left: -5vw;
    opacity: 0;
  }
}
.confetti-69 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(236.0466921195deg);
  animation: drop-69 3.3830812082s 0.1703294433s 3;
}

@keyframes drop-69 {
  25% {
    top: -22vh;
    left: -3vw;
    opacity: 1.460333403;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-70 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(29.8882603939deg);
  animation: drop-70 3.7126029571s 0.734223735s 3;
}

@keyframes drop-70 {
  25% {
    top: -5vh;
    left: -9vw;
    opacity: 0.7412221607;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-71 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(231.1719846551deg);
  animation: drop-71 3.3707606886s 0.0291852941s 3;
}

@keyframes drop-71 {
  25% {
    top: -25vh;
    left: 14vw;
    opacity: 0.7816281659;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-72 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(60.2533712518deg);
  animation: drop-72 3.2333720517s 0.8095267854s 3;
}

@keyframes drop-72 {
  25% {
    top: -22vh;
    left: -2vw;
    opacity: 1.4559656709;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-73 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(341.9318391205deg);
  animation: drop-73 3.7361986243s 0.1834364392s 3;
}

@keyframes drop-73 {
  25% {
    top: -11vh;
    left: 13vw;
    opacity: 1.3280479146;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-74 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(177.2087036441deg);
  animation: drop-74 3.8695501732s 0.8011661086s 3;
}

@keyframes drop-74 {
  25% {
    top: -22vh;
    left: 6vw;
    opacity: 0.5529647456;
  }
  100% {
    top: 50vh;
    left: 10vw;
    opacity: 0;
  }
}
.confetti-75 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(163.1680637781deg);
  animation: drop-75 3.0513345795s 0.917923877s 3;
}

@keyframes drop-75 {
  25% {
    top: -9vh;
    left: 6vw;
    opacity: 0.7498849364;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}

