@import "../shared";

.Module .module-content {
  .ecogy-form .ecogy-form-buttons .button-section button {
    float: right;
    margin-right: 10px;
  }
}

.ecogy-form {
  margin-top: 20px;
  width: 100%;
  padding-bottom: 20px;

  .field-message {
    padding-top: 7px;
    font-style: italic;
  }

  a {
    color: $ecogy-color-active;
  }

  button:disabled {
    display: none;
  }

  .disabled {
    background-color: $ecogy-darkgrey;
    color: $ecogy-lightgrey;
    border-color: $ecogy-dark;
  }

  select:disabled,
  input:disabled,
  textarea:disabled {
    background-color: $ecogy-darkgrey;
    color: $ecogy-lightgrey;
    border-color: $ecogy-dark;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    margin-left: -10px;
  }

  input[type="date"] {
    flex-direction: row-reverse;
  }

  .ecogy-form-buttons {
    position: fixed;
    bottom: 0;
    width: calc(100% - #{$menu-width} - #{$content-margin} * 2);
    height: calc(#{$height-view-controls});
    background-color: $ecogy-dark;
    z-index: 100;
    margin-left: -20px;

    .button-section {
      position: fixed;
      width: calc(100% - #{$menu-width});
      bottom: 10px;
      right: 0;

      button {
        float: right;
        margin-right: 10px;
      }
    }

    .audit-section {
      margin-bottom: 20px;

      // float: left;

      >* {
        vertical-align: middle;
        display: inline;
      }
    }

    .message-section {
      padding: 0 20px;

      button:hover:not(disabled, .disabled) {
        border: none;
      }
    }
  }

  .ecogy-button {
    color: $ecogy-white;
    background-color: $ecogy-color-active;
    border-color: $ecogy-color-active;
  }

  .icon-button {
    .material-icons {
      transform: scale(1.25);
      font-size: 24px;
      padding: 0 5px;
      top: 4px;

      // color: $ecogy-color-active;
    }
  }

  // .icon-button.btn-danger {
  //   border-color: $ecogy-status-red;
  //   .material-icons {
  //     color: $ecogy-status-red;
  //   }
  // }

  .btn {
    .material-icons {
      position: relative;
      top: 2px;
      padding-right: 5px;
      font-size: 16px;
      color: $ecogy-white;
    }

    margin-right: 10px;
  }

  .btn:last-child {
    margin-right: 0;
  }

  input[readonly] {
    border: none;
    background-color: $ecogy-darkgrey;
    color: $ecogy-white;
  }

  .readonly-label {
    padding-top: 0.75rem;
  }

  .readonly-field {
    padding: 0.75rem;
    font-size: 1rem;
  }

  .timezone-picker {
    width: 100%;
    z-index: 100;

    a {
      background-color: $ecogy-white;
      color: $ecogy-black;

      mark {
        padding: 0;
      }
    }

    ul {
      background-color: $ecogy-white;
      z-index: 100;
    }

    input {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  form {

    .date-warning,
    .size-warning {
      padding: 5px 7px 10px;
      transform: scale(1.5);
      color: orange;
    }

    .copy-icon {
      cursor: pointer;
      transform: scale(1.5);
      display: flex;
      align-items: center;
      margin-left: 16px;
      padding-top: 8px;
    }

    .multi-system-warning {
      cursor: help;
      padding: 0.55rem 5px 0 0;
      margin-left: -1.5rem;
      transform: scale(2);
      color: orange;
    }

    .btn {
      margin-right: 10px;
    }

    .form-group {
      margin-bottom: 0.25rem;
      clear: both;
    }

    legend {
      font-size: inherit;
    }

    .checkboxes {
      width: 800px;
      .checkbox-inline {
        width: 25%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      input {
        margin-right: 5px;
      }
    }

    .field-array {
      .search-hide {
        display: none;
      }

      .collapse-show {
        display: none;
      }

      .collapse-hide {
        display: block;
      }

      .collapsed {
        .field-array-entry-content {
          display: none;
        }

        .collapse-show {
          display: block;
        }

        .collapse-hide {
          display: none;
        }
      }

      .field-array-of-object .field-array-entry {
        background-color: $ecogy-darkgrey;
        border: 2px solid $ecogy-black;
        border-radius: 5px;
        margin-bottom: 5px;

        > {
          .form-group {
            margin: 5px;
          }
        }
      }

      .field-array-of-object .new-entry .field-array-entry-collapse {
        background-color: $ecogy-color-active;
      }

      .field-array-entry-collapse {
        background-color: $ecogy-black;
        max-height: 45px;
        overflow: hidden;

        h1,
        h2,
        h3,
        h4,
        h5 {
          display: inline-block;
        }

        aside {
          color: $ecogy-lightgrey;
          display: inline-block;
        }
      }

      .field-array-add {
        background-color: $ecogy-darkgrey;
        border: 2px solid $ecogy-black;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 5px;
        display: flex;

        // justify-content: flex-end;
      }

      .field-array-buttons {
        display: flex;
        background-color: $ecogy-dark;
        padding: 5px;
      }

      .field-object-inline {
        display: inline-block;
        margin-bottom: 0;
      }

      .field-inline {
        vertical-align: top;
        display: inline-block;
        margin: 3px 10px 3px 0;

        .form-control {
          display: inline-block;
        }

        div.property-wrapper {
          display: inline-block;
        }
      }

      .field-array-inline {
        margin-bottom: 0;
        position: relative;

        >div {
          display: inline-block;
        }

        .form-control {
          display: inline-block;
        }

        div.property-wrapper {
          display: inline-block;
        }

        .field-array-field-buttons {
          display: inline-flex;
          padding-left: 10px;
          position: absolute;
          bottom: 8px;

          button,
          .btn:last-child {
            margin-right: 10px;
          }
        }

        .field-array-object-buttons {
          display: flex;
          background-color: $ecogy-dark;
          padding: 5px;

          button,
          .btn:last-child {
            margin-right: 10px;
          }
        }

        hr {
          margin: 5px;
          clear: both;
        }
      }
    }

    .inline-properties {
      .property-wrapper {
        display: inline-block;
        width: 8.33%;
      }
    }

    .inline-form-group {
      display: flex;

      label {
        padding-top: 10px;
        padding-right: 5px;
        font-size: 1rem;
      }

      .inline-form-control {
        display: inline-block;
        width: 100%;
      }
    }

    .panel {
      margin-bottom: 10px;
    }

    .panel-danger {
      .panel-title {
        color: $ecogy-status-red;
      }
    }
  }

  form.split {
    .form-group {
      clear: none;
    }
  }

  .alert {
    margin-top: 20px;

    ul {
      margin-bottom: 0;
    }
  }

  .ecogy-form-panels {
    background-color: $ecogy-palegrey;
    padding: 20px;
  }

  .hidden-fields-form-template {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;

    label {
      padding-right: 0;
      padding-bottom: 0;
    }

    .hide-toggle {
      height: 38px;
      width: 2rem;
      border-radius: 0;
      margin-left: -1.5rem;
      align-self: flex-start;
      z-index: 3;
    }
  }

  .accordion-button {
    color: $ecogy-blue;
    background-color: $ecogy-black;
    border: 1px solid black;
    padding: 1rem;

    .record-form__required-hidden {
      margin-top: 0.25rem;
      margin-bottom: 0;
      padding: 0.5rem;

      i {
        padding-right: 0.25rem;
        color: $ecogy-orange;
      }
    }

    // Remove default styles from Accordion component
    background-image: none;

    &::after {
      background-image: none;
    }

    &:focus {
      z-index: 3;
      box-shadow: none;
    }
  }

  .accordion-body {
    padding-left: 0;
    padding-right: 0;
  }

  .accordion-item {
    background-color: transparent;
  }

  .error {
    color: $ecogy-status-red;
  }

  .strike-through * {
    text-decoration: line-through;
  }
}

.ecogy-form-card {
  height: 100%;
}

.ecogy-form-panels {
  margin-top: 20px;

  .loading {
    margin-top: 55px;
  }

  .card {
    min-height: 350px;
  }
}

.info.alert-info {
  padding-left: 40px;

  .material-icons {
    color: $ecogy-color-info;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.copy-field-col-flex {
  display: flex;
}

.copy-field-col-fullwidth {
  width: 100%;
}